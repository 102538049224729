import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class LottieBanner extends Vue {
  @Prop({ type: [String, Number], required: true })
  private readonly aspectRatio!: number | string;
  @Prop({ type: String, required: true }) private readonly src!: string;
  @Prop({ type: [String, Number] })
  private readonly maxWidth?: number | string;
  @Prop({ type: [String, Number] })
  private readonly width?: number | string;
}
